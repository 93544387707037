import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { api } from "./apiClass";

function Order(props) {
  const password = useSelector((state) => state.password);
  const { userid, orderid } = useParams();
  const [user, setUser] = useState({});
  const [order, setOrder] = useState({});

  useEffect(() => {
    async function getUser() {
      const userData = await api.getUser(password, userid);
      setUser(userData);
    }
    getUser();
  }, [password, userid]);

  useEffect(() => {
    async function getOrder() {
      const orderData = await api.getOrder(password, orderid);
      setOrder(orderData);
      console.log(order);
    }
    getOrder();
  }, [password, orderid]);

  return (
    <div className="Order">
      <div>{user.username}</div>
      <div>{order.hasOwnProperty("region") ? order.region : ""}</div>
      <div>{order.hasOwnProperty("state") ? order.state : ""}</div>
      <div>
        {order.hasOwnProperty("photoParam") ? (
          <img src={order.photoParam.tempUrl}></img>
        ) : (
          ""
        )}
      </div>
      <div>
        {order.hasOwnProperty("paramsOrder")
          ? order.paramsOrder.map((param) => (
              <div>
                {param.name}: {param.value}
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}

export default Order;
