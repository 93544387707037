import { useDispatch, useSelector } from "react-redux";
import User from "./User";
// import data from "./data.json";
import { api } from "./apiClass";
import { setUsers } from "./userReduser";
import { useEffect, useState } from "react";

function Users() {
  let password = useSelector((state) => state.password);
  let users = useSelector((state) => state.users);
  const [search, setSearch] = useState("");
  const [searchUsers, setSearchUsers] = useState(users.slice(0, 100));
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUsers() {
      const usersData = await api.getUsers(password);
      dispatch(setUsers(usersData));
    }

    getUsers();
  }, [dispatch, password]);

  useEffect(() => {
    setSearchUsers(users.filter(user => (user.id.toString().indexOf(search) > -1) || (user.hasOwnProperty("username") && user.username.toString().indexOf(search) > -1)).slice(0, 100));
  }, [search])
  // console.log(users);
  return (
    <div className="Users">
      <p>Search: </p>
      <input
        onChange={(e) => setSearch(e.target.value)}
        className="searchUsers"
      ></input>
      {searchUsers.map((user, key) => (
        <User
          key={key}
          id={user.id}
          username={user.username}
          coin={user.coin}
          deactivate={user.deactivate}
          order={user.order}
        ></User>
      ))}
    </div>
  );
}

export default Users;
