import { useDispatch, useSelector } from "react-redux";
import { api } from "./apiClass";
import { setWallets } from "./userReduser";

function Wallet(props) {
  const password = useSelector((state) => state.password);
  const dispatch = useDispatch();
  const handleClick = async (event) => {
    const id = event.target.getAttribute("data-id");
    if (await api.deleteWallet(password, id)) {
      const walletsData = await api.getWallets(password);
      dispatch(setWallets(walletsData));
    }
  };

  return (
    <div className="Wallet">
      <span data-id={props.id}>{props.seed}</span>
      <button onClick={handleClick} data-id={props.id}>
        Delete
      </button>
    </div>
  );
}

export default Wallet;
