import { useDispatch, useSelector } from "react-redux";
// import useFetch from "./useFetch";
import { setPassword } from "./userReduser";

// const errorToast = () => toast.error('error');



function Password() {
    let password = useSelector(state => state.password);
    const dispatch = useDispatch();
    dispatch(setPassword(localStorage.getItem("password") || ""));  
    const handleChange = (event) => {
      dispatch(setPassword(event.target.value));  
    }
//    const {
//     data,
//     loading,
//     error,
//     refresh,
//   } = useFetch('http://127.0.0.1:5000', {
//       password: 
//   })
  return (
    <div className="PasswordСontainer">
        <div>password</div>
      <input type="password" value={localStorage.getItem("password") || ""} onChange={handleChange}></input>
      {/* <button onClick={}>da</button> */}
    </div>
  );
}

export default Password;
