import { useDispatch, useSelector } from "react-redux";
import Price from "./Price";
// import data from "./data.json";
import { api } from "./apiClass";
import { setStatistics } from "./userReduser";
import { useEffect } from "react";

function Statistics() {
  let password = useSelector((state) => state.password);
  let statistics = useSelector((state) => {
    const top10States = [];
    const states = {};
    const _statistics = state.statistics;
    for (let i = 0; i < _statistics.length; i++) {
        const order = _statistics[i];
        const key = `${order.region} ${order.state}`;
        if (!states.hasOwnProperty(key)) {
            states[key] = 0;
        }else {
            states[key]++;
        }
    }
    for (let state in states) {
        top10States.push({state, count: states[state]});
    }
    top10States.sort((a, b) => b.count - a.count);
    top10States.length = 10;
    console.log(top10States);
    return top10States;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    async function getStatistics() {
      const pricesData = await api.getStatistics(password);
      dispatch(setStatistics(pricesData));
    }

    getStatistics();
  }, [dispatch, password]);
  // console.log(users);
  return (
    <div className="Prices">
      {statistics.map((statistic, key) => (
        <div key={key}>
          state: {statistic.state} - {statistic.count}
        </div>
      ))}
    </div>
  );
}

export default Statistics;