import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "./userReduser";
import { api } from "./apiClass";
import { Link } from "react-router-dom";

function User(props) {
  const users = useSelector((state) => state.users);
  const password = useSelector((state) => state.password);
  const dispatch = useDispatch();

  const handleChange = async (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.getAttribute("data-id");
    if (await api.setUserCoin(password, id, value)) {
    dispatch(
      setUsers(
        users.map((el) => {
          if (el.id === id) {
            el.coin = value;
          }
          return el;
        })
      )
    );
  }
  };
  const handleClick = async (event) => {
    const deactivate = event.target.checked;
    const id = event.target.getAttribute("data-id");
    if (await api.setUserDeactivate(password, id, deactivate)) {
      dispatch(
        setUsers(
          users.map((el) => {
            if (el.id === id) {
              el.deactivate = deactivate;
            }
            return el;
          })
        )
      );
    }
  };

  // console.log(users);
  return (
    <div className="User" id={props.id}>
      <span className="id">{props.id}</span>
      <span className="username">{props.username}</span>
      <input
        type="number"
        data-id={props.id}
        onChange={handleChange}
        value={props.coin}
        min="0"
        className="coin"
      ></input>
      <input
        type="checkbox"
        data-id={props.id}
        onClick={handleClick}
        checked={props.deactivate}
        id={"deactivate-" + props.id}
        className="deactivate"
      ></input>
      <label htmlFor={"deactivate-" + props.id}>deactivate</label>
      <Link to={`/order/${props.id}/${props.order}`}>Заказ</Link>
    </div>
  );
}

export default User;
