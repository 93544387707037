function Error(props) {
  return (
    <div className="Error">
      <span className="id">{props.userId}</span>
      <span className="username">{props.state}</span>
      <span  className="username">{props.created}</span>

      <span className="base64">{props.base64Data}</span>
      <span className="errormsg">{props.errorMsg}</span>
      <hr/>
    </div>
  );
}

export default Error;
