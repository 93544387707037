import { Link } from 'react-router-dom';

function Links() {
    
    return (
      <div className="Links">
        <Link to="/users">Users</Link>
        <Link to="/statistics">Statistics</Link>
        <Link to="/wallets">Wallets</Link>
        <Link to="/price">Price</Link>
        <Link to="/error">Error</Link>
      </div>
    );
  }
  
  export default Links;
  