import { useDispatch, useSelector } from "react-redux";
import Error from "./Error";
// import data from "./data.json";
import { api } from "./apiClass";
import { setErrors } from "./userReduser";
import { useEffect } from "react";

function Errors() {
  let password = useSelector((state) => state.password);
  let errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getErrors() {
      const errorsData = await api.getErrors(password);
      dispatch(setErrors(errorsData));
    }

    getErrors();
  }, [dispatch, password]);
  // console.log(users);
  return (
    <div className="Errors">
      {errors.map((error, key) => (
        <Error
          key={key}
          userId={error.userId}
          state={error.state}
          created={error.created}
          base64Data={error.base64Data}
          errorMsg={error.errorMsg}
        ></Error>
      ))}
    </div>
  );
}

export default Errors;