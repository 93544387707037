const SET_PASSWORD = "SET_PASSWORD";
const SET_USERS = "SET_USER";
const SET_WALLETS = "SET_WALLETS";
const SET_PRICES = "SET_PRICES";
const SET_STATISTICS = "SET_STATISTICS";
const SET_ERRORS = "SET_ERRORS";

const defaultState = {
  password: "",
  users: [],
  wallets: [],
  prices: [],
  statistics: [],
  errors: [],
};

function userReduser(state = defaultState, action) {
  switch (action.type) {
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      };
    case SET_PRICES:
      return {
        ...state,
        prices: action.payload,
      };
    case SET_STATISTICS:
      return {
        ...state,
        statistics: action.payload,
      };
    case SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}

export default userReduser;

export const setPassword = (password) => {
  localStorage.setItem('password', password);
  return {
    type: SET_PASSWORD,
    payload: password,
  }
};
export const setUsers = (users) => ({ type: SET_USERS, payload: users });
export const setWallets = (wallets) => ({
  type: SET_WALLETS,
  payload: wallets,
});
export const setPrices = (prices) => ({ type: SET_PRICES, payload: prices });
export const setStatistics = (statistics) => ({ type: SET_STATISTICS, payload: statistics });

export const setErrors = (errors) => ({ type: SET_ERRORS, payload: errors })