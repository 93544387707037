import { useDispatch, useSelector } from "react-redux";
import Price from "./Price";
// import data from "./data.json";
import { api } from "./apiClass";
import { setPrices } from "./userReduser";
import { useEffect } from "react";

function Prices() {
  let password = useSelector((state) => state.password);
  let prices = useSelector((state) => state.prices);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPrices() {
      const pricesData = await api.getPrices(password);
      dispatch(setPrices(pricesData));
    }

    getPrices();
  }, [dispatch, password]);
  // console.log(users);
  return (
    <div className="Prices">
      {prices.map((price, key) => (
        <Price
          key={key}
          id={price.id}
          title={price.title}
          price={price.value}
        ></Price>
      ))}
    </div>
  );
}

export default Prices;