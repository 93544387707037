import { useDispatch, useSelector } from "react-redux";
import Wallet from "./Wallet";
// import data from "./data.json";
import { api } from "./apiClass";
import { setWallets } from "./userReduser";
import { useEffect, useState } from "react";

function Wallets(props) {
  let password = useSelector((state) => state.password);
  let wallets = useSelector((state) => state.wallets);
  const [seed, setSeed] = useState("");
  const dispatch = useDispatch();

  const handleClick = async () => {
    console.log(seed);
    if (await api.addWallet(password, seed)) {
      const walletsData = await api.getWallets(password);
      dispatch(setWallets(walletsData));
    }
  };

  const handleChange = (event) => {
    var value = event.target.value;
    setSeed(value);
  } 

  useEffect(() => {
    async function getWallets() {
      const walletsData = await api.getWallets(password);
      
      dispatch(setWallets(walletsData));
    
    }
    
    getWallets();
}, [dispatch, password]);

console.log(wallets);
  return (
    <div className="Wallets">
      <span>wallet: </span>
      <input onChange={handleChange}></input>
      <button onClick={handleClick}>add</button>

      {wallets.map((wallet, key) => (
        <Wallet key={key} id={wallet.id} seed={wallet.seed}></Wallet>
      ))}
    </div>
  );
}

export default Wallets;
