import { useDispatch, useSelector } from "react-redux";
import { setPrices } from "./userReduser";
import { api } from "./apiClass";

function Price(props) {
  const password = useSelector((state) => state.password);
  const dispatch = useDispatch();
  const handleChange = async (event) => {
    const value = parseInt(event.target.value);
    const id = event.target.getAttribute("data-id");
    if (await api.setPrice(password, id, value)) {
      const pricesData = await api.getPrices(password);
      dispatch(setPrices(pricesData));
    }
  };

  return (
    <div className="Price">
      <span>{props.title}: </span>
      <input
        onChange={handleChange}
        type="number"
        defaultValue={props.price}
        data-id={props.id}
        min="0"
      ></input>
    </div>
  );
}

export default Price;
